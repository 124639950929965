import { useRef, useEffect } from "react";

interface LazyVideoProps {
  src: string;
}

export const LazyVideo = ({ src }: LazyVideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!videoRef.current) {
            return;
          }
          const video = videoRef.current;
          console.log("load");
          video.src = src;
          observer.disconnect();
        }
      });
    });

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [src]);

  return <video ref={videoRef} autoPlay muted loop />;
};
